<template>
  <div
    class="paragraph paragraph-text layout-grid"
    :class="'paragraph-text--' + options.position"
  >
    <h2
      v-if="headline"
      v-blokkli-editable:field_headline
      class="grid-area-t typo-headline-4 uppercase"
      :class="{
        'mb-16': subHeadline || description,
        'font-bold': isRebranding,
      }"
    >
      {{ headline }}
    </h2>
    <div
      v-if="subHeadline"
      v-blokkli-editable:field_sub_headline
      class="grid-area-s typo-lead uppercase"
      :class="{
        'mb-16': description,
      }"
    >
      {{ subHeadline }}
    </div>
    <div
      v-if="description"
      v-blokkli-editable:field_description="{ type: 'frame' }"
      class="grid-area-c typo-body-2 rich-text"
      v-html="description"
    />
  </div>
</template>

<script lang="ts" setup>
const { options } = defineBlokkli({
  bundle: 'text',
  options: {
    position: {
      type: 'radios',
      label: 'Position',
      default: 'centered',
      options: {
        centered: 'Centered',
        right: 'Right',
        editorial: 'Editorial',
      },
    },
  },

  editor: {
    addBehaviour: 'editable:field_description',
  },
})

defineProps<{
  headline?: string
  subHeadline?: string
  description?: string
}>()

const isRebranding = await useFeatureFlag()
</script>

<style lang="postcss">
/* T = Title */
/* S = Subtitle */
/* C = Content */

.paragraph-text {
  grid-template-areas:
    '. T T T T .'
    '. S S S S .'
    '. C C C C .';
}

@screen md {
  .paragraph-text--centered {
    grid-template-areas:
      '. . . T T T T T T T T . . .'
      '. . . S S S S S S S S . . .'
      '. . . C C C C C C C C . . .';
  }

  .paragraph-text--right {
    grid-template-areas:
      '. . . . . . T T T T T T T .'
      '. . . . . . S S S S S S S .'
      '. . . . . . C C C C C C C .';
  }

  .paragraph-text--editorial {
    grid-template-rows: auto 1fr;
    grid-template-areas:
      '. T T T T . S S S S S S S .'
      '. T T T T . C C C C C C C .';
  }
}
</style>
